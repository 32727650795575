@font-face {
    font-family: 'D-DIN-Condensed';
    src:url('./webfonts_D-DIN-Condensed/D-DIN-Condensed.ttf.woff') format('woff'),
        url('./webfonts_D-DIN-Condensed/D-DIN-Condensed.ttf.svg#D-DIN-Condensed') format('svg'),
        url('./webfonts_D-DIN-Condensed/D-DIN-Condensed.ttf.eot'),
        url('./webfonts_D-DIN-Condensed/D-DIN-Condensed.ttf.eot?#iefix') format('embedded-opentype'); 
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Nexa Bold';
    src: url('../style/NexaBold/Nexa\ Bold.otf') format('otf'),
         url('../style/NexaBold/Nexa\ Light.otf') format('otf'); /* Add additional formats if necessary */
    font-weight: normal;
    font-style: normal;
  }
  

$font: (main:('Nexa Bold', sans-serif), para:('Manrope', sans-serif), serv: ('klavika-web', sans-serif));
$colors: (links: #818285, main: #E7454C, tran: #585858);