@use 'variables';
@use 'mixins';

*{
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    list-style: none;
    text-decoration: none;
}



body{
    font-family: map-get(variables.$font, main);
    font-style: normal;

    // @include mixins.query(mobile){
    //     width: 100%;
    // }

    // .home{
    //     padding: 20px 60px;
    // }

    .header{
        @include mixins.flexBet;
        width: 100%;
        padding: 20px 60px;
        position: fixed;
        top: 0;
        z-index: 1000;
        background-color: #FFF;
        
      
        nav {
          @include mixins.flexer;
        //   width: 100%;

        .hamburger{
            display: none;
            position: absolute;
            top: 1.5rem;
            right: 1.5rem;
            flex-direction: column;
            justify-content: space-between;
            width: 24px;
            height: 24px;

            .bar{
                height: 0.4rem;
                width: 100%;
                background-color: #585858;
                border-radius: 0.2rem;
            }

            // @include mixins.query(mobile){
            // }
        }
      
          ul {
                @include mixins.flexEven;
                gap: 30px;
        
                li {
                    text-transform: uppercase;
                    font-family: map-get(variables.$font , main);
            
                    a {
                        color: map-get(variables.$colors, links);
                        font-size: 18px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: 23px;
            
                        &.active-link {
                        color: map-get(variables.$colors, main);
                        }
                    }
                }
            }
        }

        @include mixins.query(mobile){
            @include mixins.flexColStart;

            nav{
                .hamburger{
                    display: flex;
                }

                ul{
                    display: none;
                    flex-direction: column;
                    width: 100%;
                    margin: 0.25rem;

                    &.mobile-menu-open{
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        position: relative;
                        left: 50%;
                    }

                    li{
                        width: 100%;
                        text-align: center;

                        a{
                            margin: 0.2rem 0.5rem;
                            font-size: 18px;
                            font-style: normal;
                            font-weight: 700;
                            line-height: 23px;
                        }
                    }
                }
            }
        }
    }
      

    .hero{
        @include mixins.flexBet;
        width: 100%;
        height: 580px;
        background: url(../images/redRec.png) no-repeat right;
        padding: 20px 60px;

        @include mixins.query(mobile){
            @include mixins.flexer;
            background: none;
            width: 100%;
            height: 500px;
            padding: 0;
            // border: 1px solid red;
        }
        
        div{
            @include mixins.flexColStart;
            gap: 29px;            
            width: 400px;
            height: 300px;
            color: #fff;
            position: relative;
            right: 2.5%;

            @include mixins.query(mobile){
                @include mixins.flexCol;
                justify-content: flex-end;
                width: 100%;
                height: 100%;
                // border: 1px solid red;
                gap: 15px;
                background: url(../images/redRecMob.png) no-repeat top center;
                padding: 0 50px;
            }

            h1{
                @include mixins.head(111.2, -1);
                line-height: 89px;

                @include mixins.query(mobile){
                    line-height: 44.627px;
                }
            }

            p{
                @include mixins.para(16);
                height: 52px;
                width: 430px;
                color: #000;

                @include mixins.query(mobile){
                    width: 328px;
                    line-height: 16px;
                    height: 32px;   
                }
            }

            a{
                @include mixins.cta(1);
                text-transform: capitalize;
                // color: #000;
                @include mixins.flexer;

                @include mixins.query(mobile){
                    padding-left: 35%;
                }
            }
        }

        img{
            position: relative;
            left: 5.25%;
            display: none;
        }

        
    }

    .holding{
        @include mixins.flexCol;
        width: 100%;
        margin-top: 80px;

        @include mixins.query(mobile){
            @include mixins.flexCol;
            width: 100%;
            // padding: 0 20px;
            margin: 95px 0 0;
            // border: 1px solid red;
        }
    }

    .home{
        @include mixins.flexCol;
        // gap: 20px;

        @include mixins.query(mobile){
            @include mixins.flexCol;
            width: 100%;
            // width: 100vmax;
            padding: 0;
        }

        .us{
            @include mixins.flexer;
            padding: 20px 60px;
            margin: 40px 0;
            gap: 70px;
            width: 100%;

            .img{
                width: 556px;
                height: 660px;
                border-radius: 64px;

                .mob{
                    display: none;
                }
            }

            .text{
                @include mixins.flexColStart;
                gap: 20px;

                h4{
                    @include mixins.head(18, 1);
                    line-height: 23px;
                    color: map-get(variables.$colors, main );
                }

                h2{
                    @include mixins.head(56, -1);
                }

                p{
                    @include mixins.para(18);
                    width: 563px;
                    height: 348px;
                    font-weight: 300;
                }

                a{
                    @include mixins.cta(1);
                }
            }

            @include mixins.query(mobile){
                @include mixins.flexColR;
                gap: 20px;

                .text{
                    @include mixins.flexCol;
                    gap: 15px;
                    width: 100%;

                    h4{
                        font-size: 18px;
                    }

                    h2{
                        font-size: 32px;
                        text-align: center;
                        line-height: 32px;
                    }

                    p{
                        font-size: 14px;
                        width: 328px;
                        height: 320px;
                        line-height: 20px;
                        text-align: center;
                    }
                }

                .img{
                    @include mixins.flexer;
                    width: 328px;
                    height: 389.35px;

                    .des{
                        display: none;
                    }

                    .mob{
                        display: block;
                    }
                }
            }
        }

        .service{
            @include mixins.flexCol;
            gap: 40px;
            padding: 20px 60px;
            width: 100%;

            .top{
                @include mixins.flexCol;

                h4{
                    @include mixins.head(18, 1);
                    line-height: 23px;
                }

                h2{
                    @include mixins.head(56, -1);
                    text-align: center;
                    // width: 606px;
                }
            }

            .bot{
                @include mixins.flexer;
                // height: 100vh;

                .grider{
                    position: relative;
                    display: grid;
                    grid-template-columns: repeat(3, 1fr);
                    grid-template-rows: repeat(2, auto); // Adjust the rows as needed
                    gap: 50px;
                    left: 10px;

                    div{
                        @include mixins.flexStart;
                        gap: 13px;
                        width: 373px;

                        // &:nth-child(2){
                        //     width: 410px;
                        // }

                        span{
                            @include mixins.flexColStart;
                            width: 294px;
                            font-family: map-get(variables.$font, serv );

                            h4{
                                @include mixins.head(24, -1);
                                line-height: 30px;
                                // width: 306px;
                            }

                            p{
                                @include mixins.para(18);
                                letter-spacing: 0.27px;
                                width: 292px;
                            }
                        }
                    }
                }
            }

            a{
                @include mixins.cta(1);
            }

            @include mixins.query(mobile){
                // padding-top: 0;
                margin-bottom: 50px;

                .top{
                    h4{
                        font-size: 18px;
                    }

                    h2{
                        font-size: 32px;
                        text-align: center;
                        // width: 328px;
                        // height: 145px;
                    }
                }

                .bot{
                    @include mixins.flexer;

                    .grider{
                        @include mixins.flexCol;
                        gap: 24px;
                        left: 0;

                        div{
                            img{
                                width: 54.262px;
                                height: 54.262px;
                            }

                            span{
                                @include mixins.flexCol;
                                width: 153px;
                                gap: 15px;
                                // text-align: justify;

                                h4{
                                    font-size: 16px;
                                    line-height: 23.002px;
                                    // width: auto;
                                    height: 23px;
                                    margin-bottom: 10px;
                                }

                                p{
                                    font-size: 10.616px;
                                    line-height: 16.514px; /* 155.556% */
                                    letter-spacing: 0.159px;
                                    text-align: left;
                                    width: 151.579px;
                                }
                            }
                        }
                    }
                }
            }
        }

        .process{
            @include mixins.flexCol;
            gap: 60px;
            background: map-get(variables.$colors, tran );
            width: 100%;
            padding: 70px 60px;

            .top{
                @include mixins.flexCol;

                h4{
                    @include mixins.head(18, 0);
                    line-height: 23px;
                }

                h2{
                    @include mixins.head(56, 0);
                }

                p{
                    @include mixins.para(18);
                    color: #fff;
                }
            }

            .bot{
                @include mixins.flexer;
                gap: 30px;
                
                div{
                    width: 267px;
                    height: 342px;
                    border-radius: 64px;
                    background: #666;
                    @include mixins.flexCol;
                    gap: 15px;

                    h4{
                        @include mixins.head(24, 0);
                        line-height: 30px;
                    }

                    p{
                        @include mixins.para(14);
                        line-height: 22px;
                        color: #D8D8D8;
                        text-align: center;
                        width: 203px;
                    }
                }
            }

            a{
                @include mixins.cta(1);
            }

            @include mixins.query(mobile){
                gap: 30px;
                // padding-top: 10px;

                .top{
                    @include mixins.flexCol;
                    gap: 15px;

                    h4{
                        font-size: 12px;
                        line-height: 23px;
                    }

                    h2{
                        font-size: 38px;
                        line-height: 37px;
                    }

                    p{
                        font-size: 14px;
                        line-height: 28px;
                        text-align: center;
                        // font-family: map-get($map: $font , $key: serv );
                    }
                }

                .bot{
                    @include mixins.flexCol;
                    gap: 25px;

                    div{
                        width: 229.898px;
                        height: 294.476px;
                        border-radius: 55.107px;

                        img{
                            width: 65.45px;
                            height: 65.45px;
                        }

                        h4{
                            font-size: 18px;
                            line-height: 33.581px;
                        }

                        p{
                            font-family: map-get(variables.$font, serv );
                            font-size: 12.055px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 18.943px;
                            text-align: center;
                        }
                    }
                }
            }
        }

        .test{
            @include mixins.flexCol;
            padding: 20px 60px;
            width: 100%;
            background: #F9F9F9;
            gap: 40px;

            .top{
                @include mixins.flexColStart;
                width: 100%;
                
                h4{
                    @include mixins.head(18, 1);
                    line-height: 23px;
                }

                .tBot{
                    @include mixins.flexBet;
                    width: 100%;

                    h2{
                        @include mixins.head(56, -1);
                    }

                    .but{
                        @include mixins.flexer;
                        gap: 3px;

                        button{
                            width: 15px;
                            height: 28px;
                        }
                    }
                }
            }

            .mid{
                @include mixins.flexer;
                gap: 15px;
                min-width: 100%;
                overflow: hidden;

                .testimonial{
                    width: 350px;
                    height: 260px;
                    border-radius: 64px;
                    background: #FFF;
                    @include mixins.flexColStart;
                    padding: 10px;
                    gap: 18px;
                    // min-width: 100%;

                    p{
                        @include mixins.para(16);
                        line-height: 18px;
                    }

                    span{
                        @include mixins.flexBet;
                        width: 320px;
                        height: 66px;
                        border-radius: 48px;
                        border: 1px solid #D8D8D8;
                        background: #F9F9F9;
                        padding: 10px;

                        .hold{
                            @include mixins.flexer;
                            gap: 10px;

                            .img{
                                width: 56px;
                                height: 56px;
                                border-radius: 56px;
                            }

                            .text{
                                @include mixins.flexColStart;

                                h5{
                                    @include mixins.head(18, -1);
                                    line-height: 23px;
                                }

                                p{
                                    @include mixins.para(14);
                                    line-height: 18px;
                                    color: #949494;
                                }
                            }
                        }
                    }
                }
            }

            .line{
                border: 1px solid #D8D8D8;
                width: 100%;
            }

            .bot{
                @include mixins.flexer;

                ul{
                    @include mixins.flexer;
                    gap: 30px;
                    // justify-self: center;

                    li{
                        @include mixins.flexer;
                        gap: 12px;

                        div{
                            @include mixins.flexColStart;

                            h5{
                                @include mixins.head(48, -1);
                                line-height: 59px;
                            }

                            p{
                                @include mixins.para(18);
                                letter-spacing: 0.2px;
                                line-height: normal;
                                color: #585858;
                            }
                        }   
                    }
                }
            }

            @include mixins.query(mobile){
                padding: 20px 10px;
                .top{
                    h4{
                        font-size: 12px;
                    }

                    .tBot{
                        @include mixins.flexCol;
                        width: 100%;
                        // gap: 15px;
                        h2{
                            font-size: 32px;
                            // text-align: justify;
                            line-height: 32px;
                        }

                        .but{
                            display: none;
                        }
                    }


                }

                .mid{
                    @include mixins.flexCol;
                    // display: none;
                    gap: 15px;
                    min-width: 100%;
                    overflow: hidden;
                    // width: 100%;

                    .testimonial{
                        width: 310.508px;
                        height: 243.732px;
                        border-radius: 35.614px;
                        padding: 20px;
                        // margin: 0 10px;

                        p{
                            font-family: map-get(variables.$font, serv );
                            font-size: 13.355px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 18.92px;
                            text-align: justify;
                        }

                        span{
                            width: 275px;
                            height: 53.421px;
                            border-radius: 26.71px;
                            position: relative;
                            right: 1%;

                            .hold{
                                .text{
                                    h5{
                                        font-size: 12px;
                                        font-style: normal;
                                        font-weight: 700;
                                        line-height: 12.799px;
                                    }

                                    p{
                                        font-family: map-get(variables.$font, serv );
                                        font-size: 10px;
                                        font-style: normal;
                                        font-weight: 400;
                                        line-height: 10.016px;
                                    }
                                }
                            }

                            img{
                                width: 17.807px;
                                height: 17.807px;
                            }
                        }
                    }

                    // @media (max-width:375px) {
                    //     .testimonial{
                    //         width: 280px;
                    //     }
                    // }
                }

                .bot{
                    ul{
                        display: grid;
                        grid-template-columns: repeat(2, 1fr);
                        grid-template-rows: repeat(2, auto);
                        gap: 28px;

                        li{
                            img{
                                width: 35.614px;
                                height: 35.614px;
                            }

                            div{
                                @include mixins.flexColStart;
                                // text-align: justify;
                                
                                h5{
                                    font-size: 26.71px;
                                    font-style: normal;
                                    font-weight: 700;
                                    line-height: 32.832px;
                                }

                                p{
                                    font-family: map-get(variables.$font , serv );
                                    font-size: 10.016px;
                                    font-style: normal;
                                    font-weight: 400;
                                    line-height: normal;
                                    letter-spacing: 0.111px;
                                    text-align: left;
                                }

                                &:last-child{
                                    p{
                                        width: 99.051px;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            // @include mixins.query(smallMob){
            //     .mid{
            //         @include mixins.flexCol;
            //         // display: none;
            //         gap: 10px;
            //         min-width: 100%;
            //         overflow: hidden;
            //         // width: 100%;

            //         .testimonial{
            //             width: 250px;
            //             height: 240px;
            //             border-radius: 35.614px;
            //             padding: 20px;
            //             // margin: 0 10px;

            //             p{
            //                 font-family: map-get(variables.$font, serv );
            //                 font-size: 10px;
            //                 font-style: normal;
            //                 font-weight: 400;
            //                 line-height: 16px;
            //                 text-align: justify;
            //             }

            //             span{
            //                 width: 260px;
            //                 height: 45px;
            //                 border-radius: 20px;
            //                 position: relative;
            //                 right: 1%;

            //                 .hold{
            //                     .text{
            //                         h5{
            //                             font-size: 12px;
            //                             font-style: normal;
            //                             font-weight: 700;
            //                             line-height: 12.799px;
            //                         }

            //                         p{
            //                             font-family: map-get(variables.$font, serv );
            //                             font-size: 10px;
            //                             font-style: normal;
            //                             font-weight: 400;
            //                             line-height: 10.016px;
            //                         }
            //                     }
            //                 }

            //                 img{
            //                     width: 17.807px;
            //                     height: 17.807px;
            //                 }
            //             }
            //         }
            //     }
            // }

           
        }

        .clients{
            height: 600px;
            gap: 80px;

            h1{
                @include mixins.head(56, -1);
                // word-wrap: break-word;
                width: 556px;
                text-align: center;
            }

            .Marquee{
                @include mixins.flexLineStart;
                gap: 19.597px;
                // box-shadow: 0px 12px 72px 0px rgba(0, 0, 0, 0.08);
                background: #fff;

                .img{
                    @include mixins.flexer;
                    gap: 20px;

                    div{
                        @include mixins.flexer;
                        width: 171.477px;
                        height: 171.477px;
                        border-radius: 48.993px;
                        background: rgba(0, 0, 0, 0.08);
                        // box-shadow: 0px 12px 72px 0px rgba(0, 0, 0, 0.08);
                    }
                }
            }

            @include mixins.query(mobile){
                // @include mixins.flexColLineStart;
                gap: 30px;

                h1{
                    position: relative;
                    right: 15%;
                    top: -15%;
                    font-size: 32px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 32px;
                    width: 280px;
                    text-align: left;

                }
            }
        }

        
    }

    .footer{
        @include mixins.footer;
    }

    .about{
        @include mixins.flexCol;

        // .header{
        //     background: none;
        // }

        .hero{
            background: url(../images/about.png) no-repeat center ;

            @include mixins.hero;

           

            img{
                display: block;
            }

            h1{
                color: #FFF;
            }

            @include mixins.query(mobile){
                div{
                    h1{
                        position: relative;
                        bottom: 30%;
                    }
                }

                img{
                    display: none;
                }
            }
        }

        .who{
            padding: 80px 60px;
            @include mixins.flexer;
            gap: 50px;
            width: 100%;

            .img{
                position: relative;
                right: 34px;
                width: 556px;
                height: 660px;
                border-radius: 64px;
                background: url(../images/group-afro-americans-working-together\ \(1\)\ 1.png);
            }

            .text{
                position: relative;
                right: 15px;
                @include mixins.flexColStart;
                gap: 15px;
                width: 500px;

                h1{
                    @include mixins.head(56, -1);
                }

                p{
                    @include mixins.para(18);
                    // height: 290px;
                    word-wrap: break-word;
                    color: #585858;
                    text-align: justify;
                }
            }
        }

        .test{
            @include mixins.testi;
            padding: 80px 60px;
            background-color: #FFF;
        }

        .misVis{
            @include mixins.flexCol;
            background: var(--Grey-6, #F9F9F9);
            padding: 0 60px 60px;
            gap: 60px;
            width: 100%;

            .top{
                @include mixins.flexer;

                div{
                    @include mixins.flexer;
                    width: 474px;
                    height: 126.4px;
                    align-self: stretch;
                    background: #fff;
                    cursor: pointer;
                    border-bottom: 4px solid transparent;

                    &.active{
                        border-bottom-color: #e7454c;
                    }

                    &.inactive {
                        h1{
                            color: #949494;
                        }
                    }

                    h1{
                        @include mixins.head(48, -1);

                        &.inactive {
                            color: #949494;
                        }
                    }
                }
            }

            .bot{
                p{
                    @include mixins.para(18);
                    text-align: center;
                    color: var(--Grey-1, #585858);
                }
            }
        }

        @include mixins.query(mobile){
            width: 100%;
            .who{
                @include mixins.flexColR;
                gap: 40px;
                padding: 80px 0;
                // padding: 0;

                // width: 100%;    

                .img{
                    width: 328px;
                    height: 389.35px;
                    right: 0;
                    background: url(../images/group-afro-americans-working-together\ mob.png);
                }

                .text{
                    @include mixins.flexCol;
                    right: 0;
                    width: 100%;

                    h1{
                        font-size: 38px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: 44.6px;
                    }

                    p{
                        text-align: center;
                        font-family: map-get(variables.$font , serv );
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 20px;
                        width: 328px;
                        height: 220px;
                    }
                }
            }

            .misVis{
                .top{
                    div{
                        width: 164px;
                        height: 43.73px;
    
                        h1{
                            font-size: 16.608px;
                            font-style: normal;
                            font-weight: 700;
                            line-height: 20.413px;
                        }
                    }
                }

                .bot{
                    p{
                        font-family: map-get(variables.$font , serv );
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 21.4px;
                        text-align: center;
                    }
                }
            }
        }
    }
//
    .contact{
        @include mixins.flexCol;

        // .header{
        //     background: none;
        // }
        
        .hero{
            background: url(../images/contact.png) no-repeat center ;

           @include mixins.hero;

            img{
                display: block;
            }

            div{
                width: 420px;

                h1{
                    color: #FFF;
                }
            }

            @include mixins.query(mobile){
                div{
                    h1{
                        position: relative;
                        bottom: 30%;
                    }
                }

                img{
                    display: none;
                }
            }
        }

        .feel{
            padding: 40px 60px;
            @include mixins.flexCol;
            gap: 30px;
            width: 100%;

            // border: 1px solid red;

            .top{
                @include mixins.flexCol;
                width: 100%;
                // border: 1px solid red;

                h1{
                    @include mixins.head(56, -1);
                }

                p{
                    @include mixins.para(18);
                    color: #585858;
                }
            }

            .bot{
                @include mixins.flexer;
                gap: 30px;

                .hold{
                    @include mixins.flexCol;
                    width: 364px;
                    height: 314px;
                    border-radius: 64px;
                    background: var(--Grey-6, #F9F9F9);
                    gap: 15px;

                    .img{
                        width: 96px;
                        height: 96px;
                        background: url(../images/loc.svg) 50% 33% no-repeat;
                        filter: drop-shadow(0px 12px 72px rgba(0, 0, 0, 0.08));
                        border-radius: 50%;
                    }

                    .img2{
                        background-image: url(../images/mail.svg);
                    }

                    .img3{
                        background-image: url(../images/call.svg);
                    }

                    h4{
                        @include mixins.head(32, -1);
                        line-height: 39px;
                    }

                    p{
                        @include mixins.para(18);
                        color: #585858;
                        text-align: center;
                    }
                }
            }
        }

        .touch{
            @include mixins.flexer;
            padding: 40px 60px;
            gap: 30px;
            width: 100%;

            .form{
                position: relative;
                @include mixins.flexColStart;
                gap: 8px;
                right: 20px;
                // width: 100%;

                h1{
                    @include mixins.head(56, -1);
                }

                p{
                    @include mixins.para(18);
                    color: #585858;
                }

                form{
                    @include mixins.flexColStart;
                    gap: 15px;

                    label{
                        @include mixins.head(16, -1);
                        line-height: 20px;
                    }

                    input{
                        width: 554px;
                        height: 48px;
                        flex-shrink: 0;
                        border-radius: 8px;
                        border: 1px solid var(--Grey-5, #D8D8D8);
                        background: var(--White, #FFF);
                        padding: 10px;

                        &::placeholder{
                            @include mixins.para(14);
                            color: #949494;
                            line-height: 18px;
                        }
                    }

                    .hold{
                        @include mixins.flexColStart;

                        textarea{
                            width: 554px;
                            height: 144px;
                            flex-shrink: 0;
                            border-radius: 8px;
                            border: 1px solid var(--Grey-5, #D8D8D8);
                            background: var(--White, #FFF);
                            padding: 10px;
                        }
                    }
                    
                    #hold{
                        @include mixins.flexer;
                        gap: 30px;

                        div{
                            @include mixins.flexColStart;
                            gap: 10px;

                            input{
                                width: 250px;
                                height: 40px;
                                flex-shrink: 0;
                                border-radius: 8px;
                                border: 1px solid var(--Grey-5, #D8D8D8);
                                background: var(--White, #FFF);
                            }
                        }
                    }

                    button{
                        @include mixins.cta(1);
                        width: 554px;
                        cursor: pointer;
                    }
                }
            }

            .img{
                position: relative;
                @include mixins.flexColLineStart;
                height: 660px;
                width: 556px;
                border-radius: 64px;
                background: url(../images/smiling-portrait-confident-young-businesswoman-holding-digital-tablet-talking-mobile-phone\ 1.png) no-repeat;
                left: 25px;
            }
        }

        .test{
            @include mixins.testi;
            padding: 80px 60px;
            background-color: #FFF;
        }

        @include mixins.query(mobile){
            .feel{
                width: 100%;
                .top{
                    h1{
                        font-size: 24px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: 44.6px; 
                    }

                    p{
                        font-family: map-get(variables.$font , serv );
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 20px;
                    }
                }

                .bot{
                    display: grid;
                    grid-template-columns: repeat(2, 1fr);
                    grid-template-rows: repeat(2, auto);

                    .hold{
                        width: 165px;
                        height: 140px;
                        border-radius: 27.846px;

                        .img{
                            width: 41.768px;
                            height: 41.768px;
                            background: url(../images/ad.svg) 50% 33% no-repeat;
                        }

                        .img2{
                            background: url(../images/maill.svg) 50% 33% no-repeat;
                        }

                        .img3{
                            background: url(../images/pNum.svg) 50% 33% no-repeat;
                        }

                        h4{
                            font-size: 15px;
                            font-style: normal;
                            font-weight: 700;
                            line-height: 16.968px;
                        }

                        p{
                            font-family: map-get(variables.$font , serv );
                            font-size: 10px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 12.182px;
                        }
                    }
                }
            }

            .touch{

                .form{
                    right: 0;
                    // width: 100%;

                    h1{
                        font-size: 32px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: 40.852px;
                    }

                    p{
                        font-family: map-get(variables.$font , serv );
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 17.17px;
                    }

                    form{
                        @include mixins.flexColStart;

                        label{
                            font-size: 12px;
                            font-style: normal;
                            font-weight: 700;
                            line-height: 13.617px;
                        }

                        input{
                            width: 328px;
                            height: 28.419px;
                            border-radius: 4.736px;

                            &::placeholder{
                                font-family: map-get(variables.$font , serv );
                                font-size: 8.289px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: 10.657px; 
                            }
                        }

                        .hold{
                            textarea{
                                width: 328px;
                                height: 85.256px;
                                border-radius: 4.736px;
                            }
                        }

                        #hold{
                            gap: 15px;

                            div{
                                input{
                                    width: 155.119px;
                                    height: 28.419px;
                                    border-radius: 4.736px;
                                }
                            }
                        }
                    }
                }

                .img{
                    display: none;
                }
            }
        }
    }

    .serv{
        @include mixins.flexCol;
        width: 100%;
        
        .hero{
            background: url(../images/services.png) no-repeat center ;

            @include mixins.hero;

            img{
                display: block;
            }

            div{
                width: 480px;

                h1{
                    color: #FFF;
                }

                p{
                    color: #FFF;
                }
            }

            @include mixins.query(mobile){
                div{
                    h1{
                        position: relative;
                        bottom: 30%;
                    }
                }

                img{
                    display: none;
                }
            }

        }

        .lHold{
            @include mixins.flexer;
            padding: 80px 60px;
            gap: 50px;
            width: 100%;

            .img{
                position: relative;
                right: 20px;
                width: 556px;
                height: 660px;
                border-radius: 64px;
            }

            .img2{
                display: none;
            }

            .text{
                position: relative;
                left: 20px;
                @include mixins.flexColStart;
                gap: 10px;

                h2{
                    @include mixins.head(56, -1);
                }

                p{
                    @include mixins.para(18);
                    color: #585858;
                }

                ul{
                    @include mixins.flexColStart;
                    gap: 10px;

                    li{
                        @include mixins.flexer;
                        gap: 8px;
                        @include mixins.para(18);
                        color: #585858;

                        .red{
                            width: 16px;
                            height: 16px;
                            background: #E7454C;
                        }

                    }
                }
            }

            @include mixins.query(mobile){
                @include mixins.flexColR;
                padding: 50px;

                .img{
                    display: none;
                }

                .img2{
                    display: block;
                    // border: 1px solid red;
                    
                    img{
                        width: 328px;
                        height: 312px;
                        border-radius: 37.755px;
                    }
                }

                .text{
                    @include mixins.flexColStart;
    
                    h2{
                        font-size: 24px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: 44.376px;
                    }
    
                    p{
                        font-family: map-get(variables.$font , serv );
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 18.651px;
                        text-align: justify;
                    }

                    ul{
                        li{
                            font-family: map-get(variables.$font, serv );
                            font-size: 12px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 18.651px;

                            .red{
                                width: 10.29px;
                                height: 10.29px;
                            }
                        }
                    }
                }
            }
        }

        .rHold{
            @include mixins.flexer;
            padding: 80px 60px;
            gap: 50px;
            width: 100%;

            .img{
                position: relative;
                left: 20px;
                width: 556px;
                height: 660px;
                border-radius: 64px;
            }

            .img2{
                display: none;
            }

            .text{
                position: relative;
                right: 20px;
                @include mixins.flexColStart;
                gap: 10px;

                h2{
                    @include mixins.head(56, -1);
                }

                p{
                    @include mixins.para(18);
                    color: #585858;
                }

                ul{
                    @include mixins.flexColStart;
                    gap: 10px;

                    li{
                        @include mixins.flexer;
                        gap: 8px;
                        @include mixins.para(18);
                        color: #585858;

                        .red{
                            width: 16px;
                            height: 16px;
                            background: #E7454C;
                        }

                    }
                }
            }

            @include mixins.query(mobile){
                @include mixins.flexCol;

                .img{
                    display: none;
                }

                .img2{
                    display: block;
                    // border: 1px solid red;
                    
                    img{
                        width: 328px;
                        height: 312px;
                        border-radius: 37.755px;
                    }
                }

                .text{
                    @include mixins.flexColStart;
    
                    h2{
                        font-size: 24px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: 44.376px;
                    }
    
                    p{
                        font-family: map-get(variables.$font , serv );
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 18.651px;
                        text-align: justify;
                    }

                    ul{
                        li{
                            font-family: map-get(variables.$font, serv );
                            font-size: 12px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 18.651px;

                            .red{
                                width: 10.29px;
                                height: 10.29px;
                            }
                        }
                    }
                }
            }
        }

        // @include mixins.query(mobile){
            
        // }
    }

    .lead {
        @include mixins.flexCol;

        // .header{
        //     background: none;
        // }
    
        .hero{
            background: url(../images/leader.png) no-repeat center ;

            @include mixins.hero;

            img{
                display: block;
            }

            div{
                width: 565px;

                h1{
                    color: #FFF;
                }

                p{
                    color: #FFF;
                }
            }

            @include mixins.query(mobile){
                div{
                    h1{
                        position: relative;
                        bottom: 30%;
                    }
                }

                img{
                    display: none;
                }
            }
        }
    
        .board {
            margin: 30px; // Change padding to margin if you want spacing around the entire board
            @include mixins.flexCol;
            gap: 80px;
            row-gap: 50px;
            column-gap: 80px;

    
            h1 {
                @include mixins.head(56, -1);
            }
    
            .hold {
                display: grid;
                grid-template-columns: repeat(3, 1fr);
                grid-template-rows: repeat(2, 1fr);
                gap: 25px;
    
                .holder {
                    @include mixins.flexCol;
    
                    img {
                        width: 280px;
                        height: 400px;
                        border-radius: 72.316px;

                        // @include mixins.of-type(1){
                        //     height: 350px;
                        // }

                        // @include mixins.of-type(9){
                        //     position: relative;
                        //     right: 50%;
                        //     width: 500px;
                        // }
                    }

                    &:last-child{
                        position: relative;
                        left: 109%;
                    }

                    &:nth-child(2){
                        height: 360px;
                        align-self: flex-end;
                    }
    
                    h5 {
                        @include mixins.head(32, 0);
                        color: #818285;
                        line-height: 39px;
                    }
    
                    p {
                        @include mixins.para(16);
                        color: #585858;
                    }
    
                    &:hover {
                        cursor: pointer;
                        // box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
                        transition: box-shadow 0.3s ease-in-out;
                    }
                }
            }

            .modal-overlay {
                background: rgba(0, 0, 0, 0.7); /* Adjust the opacity as needed */
                position: fixed;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                z-index: 997; /* Ensure it appears below the modal */
                visibility: hidden;
                opacity: 0;
                transition: opacity 0.3s ease-in-out;
            }
    
            .modal {
                padding: 65px 20px 70px;
                width: 990px;
                height: 510px;
                border-radius: 50px;
                background: var(--White, #FFF);
                @include mixins.flexCol;
                position: fixed;
                top: 45%;
                left: 45%;
                margin-top: -200px;
                margin-left: -425px;
                transition: opacity 0.3s ease-in-out;
                opacity: 0;
    
                &.open {
                    opacity: 1;
            
                    .modal-overlay {
                        visibility: visible;
                        opacity: 1;
                        z-index: 999;
                        box-shadow: 0 0 40px rgba(0, 0, 0, 0.5); // Even box shadow
                    }
                }
            
    
                button {
                    position: absolute;
                    top: 20px;
                    right: 20px;
                    width: 30.526px;
                    height: 30.526px;
                    border: none;
                    @include mixins.flexer;
                    background: url(../images/iconoir_cancel.svg) no-repeat center;
    
                    &:hover {
                        cursor: pointer;
                    }
                }
    
                div {
                    @include mixins.flexer;
                    gap: 20px;
    
                    img {
                        width: 302.863px;
                        height: 387.937px;
                        border-radius: 72.596px;
                    }
    
                    span {
                        @include mixins.flexColStart;
                        gap: 8px;
    
                        h5 {
                            @include mixins.head(48, 0);
                            color: #818285;
                            line-height: 59px;
                        }
    
                        p {
                            @include mixins.para(16);
                            line-height: 34.342px;
                            text-align: justify;
                        }

                        .mob{
                            display: none;
                        }
                    }
                }
    
                .modal-overlay {
                    position: fixed;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background: rgba(0, 0, 0, 0.5);
                    z-index: 998; // Ensure it appears below the modal
                    visibility: hidden;
                    opacity: 0;
                    transition: opacity 0.3s ease-in-out;
                }
            }
    
            &.modal-open {
                overflow: hidden; // Prevent scrolling when modal is open
    
                // .modal {
                //     // Additional styles for modal-open if needed
                // }
            }
        }
    
        .modal-open {
            overflow: hidden; // Prevent scrolling when modal is open
        }

        @include mixins.query(mobile){
            .board{
                gap: 20px;
                column-gap: 20px;
                row-gap: 20px;
                .hold{
                    grid-template-columns: repeat(2, 1fr);
                    grid-template-rows: repeat(2, 1fr);
                    gap: 10px;

                    .holder{
                        img{
                            width: 160px;
                            height: 204.944px;
                            border-radius: 38.352px;
                        }

                        &:last-child{
                            left: 0;
                        }

                        h5{
                            font-size: 16.971px;
                            font-style: normal;
                            font-weight: 700;
                            line-height: 20.683px;
                        }

                        p{
                            font-family: map-get(variables.$font, serv );
                            font-size: 8.485px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 15.38px;
                        }
                    }
                }

                .modal{
                    width: 350px;
                    height: 250px;
                    top: 80%;
                    left: 110%;

                    button{
                        right: 15px;
                        width: 15px;
                        height: 15px;
                    }

                    div{
                        img{
                            width: 160px;
                            height: 204.944px;
                            border-radius: 38.352px;
                        }

                        span{
                            h5{
                                font-size: 16.971px;
                                font-style: normal;
                                font-weight: 700;
                                line-height: 20.683px;
                            }
    
                            p{
                                font-family: map-get(variables.$font, serv );
                                font-size: 8.485px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: 15.38px;
                                // height: 100%;
                                // width: 100%;
                            }

                            .des{
                                display: none;
                            }

                            .mob{
                                display: block;
                            }
                        }
                    }
                }
            }
        }
    }
    
    
    
    //
}