@use 'variables';

@mixin flexer{
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin flexLine{
  @include flexer;
  display: inline-flex;
}

@mixin flexLineStart{
  @include flexLine();
  align-items: flex-start;
}

@mixin flexColLineStart{
  @include flexLineCol();
  align-items: flex-start;
}

@mixin flexEnd{
  @include flexer;
  align-self: flex-end;
}

@mixin flexLineCol{
  @include flexLine;
  flex-direction: column;
}

@mixin flexCol{
  @include flexer;
  flex-direction: column;
}

@mixin flexColR{
  @include flexCol;
  flex-direction: column-reverse;
}

@mixin flexBet{
  @include flexer;
  justify-content: space-between;
}

@mixin flexEven{
  @include flexer;
  justify-content: space-evenly;
}

@mixin flexColEven{
  @include flexCol;
  justify-content: space-evenly;
}

@mixin flexColBet{
  @include flexCol;
  justify-content: space-between;
}

@mixin flexStart{
  @include flexer;
  align-items: flex-start;
}

@mixin flexColStart{
  @include flexCol;
  align-items: flex-start;
}

@mixin query ($breakpoint){
  @if $breakpoint == mobile{
    @media only screen and (max-width: 481px) {
      @content;
    }
  }

  @if $breakpoint == tablet {
    @media only screen and (max-width: 768px) {
      @content;
    }
  }
}

// @mixin cta{
//   display: inline-block;
//   width: 351px;
//   height: 64px;
//   // display: flex;
//   align-items: center;
//   justify-content: center;
//   border-radius: 8px;
//   padding: 0 4px 4px 0;
//   position: relative; /* Add position: relative; for pseudo-element */

//   @include query(mobile){
//     width: 237.517px;
//     height: 48px;
//   }

//   &::before {
//     content: "";
//     background-image: url(../../images/cta.svg);
//     background-size: cover;
//     background-repeat: no-repeat;
//     width: 100%;
//     height: 100%;
//     position: absolute;
//     top: 0;
//     left: 0;
//     z-index: -1; /* Move the pseudo-element behind the content */
//     transform: scaleY(-1) scaleX(-1); /* Flip the background image */
//     border-radius: 8px;
//   }


//   a{
//     color: #fff;
//     text-transform: capitalize;
//     font-style: 16px;
//     font-weight: 500;
//     line-height: 16px;
//     background-color: map-get(variables.$colors , cta );
//     width: 100%;
//     height: 100%;
//     border-radius: 8px;
//     @include flexer;
//   }
// }

@mixin of-type($num){
  @if $num == 1{
    &:nth-of-type(1){
      @content;
    }
  }

  @else if $num == 2{
    &:nth-of-type(2){
      @content;
    }
  }

  @else if $num == 3{
    &:nth-of-type(3){
      @content;
    }
  }

  @else if $num == 4{
    &:nth-of-type(4){
      @content;
    }
  }

  @else if $num == 5{
    &:nth-of-type(5){
      @content;
    }
  }
}

@mixin para($num){
    font-size: $num * 1px;
    font-weight: 400;
    line-height: 29px;
    font-family: map-get(variables.$font, serv);

  
    @include query(mobile){
      text-align: center;
      font-size: 12px;
      line-height: 16px;
    }
}

@mixin head($num, $coll){
  font-size: $num * 1px;
  font-weight: 700;
  line-height: 69px;

  @if $coll == 1{
    color: map-get(variables.$colors , main );
  } @else if $coll == 0{
    color: #fff;
  } @else if $coll == -1{
    color: #000;
  }

  @include query(mobile){
    text-align: center;
    font-size: 48px;
    line-height: 44.627px;
  }

  
}

// @mixin ifState($num){
//   @if $num
// }

@mixin cta($col){
  @include flexer;
  padding: 20px 55px;
  gap: 10px;
  border-radius: 8px;
  border: 1px solid #fff;
  color: #fff;

  @if $col == 1{
    background-color: map-get(variables.$colors , main );
    border: none;

    &:hover{
      background-color: #fff;
      color: map-get(variables.$colors , main );
      border: 1px solid map-get(variables.$colors , main );
    }
  }

  @else if $col == 0{
    background-color: none;

    &:hover{
      background-color: #fff;
      color: map-get(variables.$colors , main );
    }
  }

  
  a{
    text-align: center;
    @include flexer;
    @include para(18);
    font-style: normal;
    font-weight: 700;
    line-height: 23px;
    color: #fff;
    font-family: map-get(variables.$font , main);
  }

  @include query(mobile){
      @include flexer;
      display: inline-flex;
      padding: 20px 55px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      border-radius: 8px;
      width: 277px;

      Link{
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 23px; 
        // @include para(14);
        // padding: 10.028px 40px;
        // line-height: 11.533px;
      }
  }
}

@mixin testi{
  @include flexCol;
  padding: 20px 60px;
  width: 100%;
  background: #F9F9F9;

  .top{
    display: none;
  }

  .mid{
    display: none;
  }

  .line{
    display: none;
  }

  .bot{
    @include flexer;

    ul{
      @include flexer;
      gap: 30px;
      // justify-self: center;

      li{
        @include flexer;
        gap: 12px;

        div{
          @include flexColStart;

          h5{
            @include head(48, -1);
            line-height: 59px;
          }

          p{
            @include para(18);
            letter-spacing: 0.2px;
            line-height: normal;
            color: #585858;
          }
        }   
      }
    }

    @include query(mobile){
      ul{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, auto);
        gap: 28px;

        li{
            img{
                width: 35.614px;
                height: 35.614px;
            }

            div{
                @include flexColStart;
                // text-align: justify;
                
                h5{
                    font-size: 26.71px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 32.832px;
                }

                p{
                    font-family: map-get(variables.$font , serv );
                    font-size: 10.016px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    letter-spacing: 0.111px;
                    text-align: left;
                }

                &:last-child{
                    p{
                        width: 99.051px;
                    }
                }
            }
        }
    }
    }
  }
}

@mixin footer{
  @include flexCol;
  padding: 80px 60px 40px;
  width: 100%;
  background: #585858;
  gap: 60px;
  //build

  .top{
    @include flexBet;
    width: 1100px;
    height: 338px;
    border-radius: 64px;
    border: 1px solid #6D6D6D;
    background: #666;
    padding: 60px;

    h1{
      @include head(56, 0);
      width: 631px;
    }

    a{
      @include cta(1);
    }
  }

  footer{
    @include flexBet;
    width: 100%;

    .left{
      @include flexCol;
      gap: 15px;

      ul{
        @include flexer;
        gap: 20px;
      }   
    }

    .right{
      @include flexCol;
      gap: 20px;

      ul{
          @include flexer;
          gap: 30px;

          a{
              @include head(18, 0);
              line-height: 23px;
          }
      }

      p{
        @include para(16);
        line-height: 26px;
        color: #fff;
        font-family: map-get(variables.$font , para );
      }
    }
  }

  @include query(mobile){
    .top{
      @include flexCol;
      width: 328px;
      height: 280px;
      border-radius: 24px;
      gap: 20px;

      h1{
        width: 249px;
        text-align: center;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 32px;
      }

      a{
        width: 277px;
        align-items: center;
        justify-content: center;
        text-align: center;
      }
    }

    footer{
      @include flexColStart;
      gap: 18px;

      .left{
        gap: 20px;

        ul{
          position: relative;
          right: 8%;
        }
      }

      .right{
        ul{
          position: relative;
          right: 8%;
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          grid-template-rows: repeat(2, 1fr);

          a{
            font-size: 18px;
            font-style: normal;
            font-weight: 700;
            line-height: 23px;
          }

          li{
            &:nth-of-type(2){
              position: relative;
              left: 50%;
            }

            &:nth-of-type(3){
              position: relative;
              left: 75%;
            }
          }
        }
      }
    }
  }
}

@mixin hero{
  @include query(mobile){
  // background: url(../images/aboutHeadMob.png) no-repeat center;
  // width: 100%;
  // height: 330px;

  
  div{
    background: url(../images/aboutMob.png) no-repeat top 110% center;
    justify-content: center;
  }

  background-size: cover;
}
}